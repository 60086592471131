import React from "react";

//Components
import { Modal, Button } from "@components/anti";

const ForgotPasswordModal = ({ id, isShowing, hide }) => {
  return (
    <Modal
      id={id}
      isShowing={isShowing}
      hide={hide}
      className="centered modal-sm"
      contentClassName={"px-0 py-4"}
    >
      <div className="modal-forgot-password">
        <div className="container">
          <h3 className="title">Lupa Password</h3>
          <div className="description py-3">
            <p>Hubungi Eatlah HQ untuk mendapatkan password baru</p>
          </div>
          <div className="">
            <Button className="btn-forgot w-100" onClick={() => hide()}>
              Ok, mengerti
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
