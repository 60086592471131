export const msg_toast_success = `
   <p className="message-toast">
      Your account has been created.
      <br /> Welcome to eatlah
   </p>
`;

export const mg_toast_barcode_sample = (phone) =>
  `<p className="message-toast">
      Congratulations ${phone}
   </p>`;

export const mg_toast_success_give_stamp = (phone) =>
  `<p className="message-toast">
      Stamp already give to you
   </p>`;

export const msg_toast_wrong_password = (phone) =>
  `<p className="message-toast">
      Username/Password yang anda <br /> masukkan salah
   </p>`;

export const msg_general_error = (phone) =>
  `<p className="message-toast">
      Terjadi kesalahan di server
   </p>`;

export const msg_custom = (text) => `
      <p className="message-toast">
         ${text}
      </p>
   `;
