import { useState, useEffect } from "react";

//Utils import
import { AsyncAPI, TokenValidation } from "src/lib/utils";
import Cookies from "js-cookie";
import { useStore } from "src/lib/store";
import {
  msg_toast_wrong_password,
  msg_general_error,
} from "data/toast-message";
import { useRouter } from "next/router";
import { msg_custom } from "data/toast-message";

//Components
import { HookForm, HookInput, Button, Modal, Image } from "@components/anti";
import ForgotPasswordModal from "@components/shared/forgot_password_modal";
import Layout from "@components/layout";

export default function LoginPage() {
  //Utils & Constant ******************************** //
  const modalForgotId = "modal-forgot-id";
  //Utils & Constant ******************************** //

  //States ****************************************** //
  const [state, setState] = useState({
    forgotModalShow: null,
    isLoading: false,
    showPassword: false,
  });

  const Router = useRouter();

  const { setLoading, setToast } = useStore((state) => state);
  //States ****************************************** //

  //Functions *************************************** //
  const handleChangeState = (value) => {
    if (typeof value == "object") setState((prev) => ({ ...prev, ...value }));
    else alert("Must pass object type on value");
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let { username, password } = values;
    let { data, errors } = await AsyncAPI({
      method: "POST",
      variables: { username, password },
      url: "/login",
    });
    if (data) {
      setTimeout(() => {
        let { access_token } = data;
        Cookies.set(process.env.NEXT_PUBLIC_ACCESS_TOKEN, access_token);
        Router.push("/");
      }, 1000);
    } else {
      setLoading(false);
      if (errors?.errors?.login === "Invalid credentials.") {
        setToast({
          isToast: true,
          message: msg_toast_wrong_password,
          status: "danger",
        });
      } else {
        setToast({
          isToast: true,
          message: errors?.message || msg_general_error,
          status: "danger",
        });
      }
      //!NEED ERROR HANDLING
      // handleChangeState({ isLoading: true });
    }
  };
  //Functions *************************************** //

  useEffect(() => {
    setToast({
      isToast: true,
      message: () =>
        msg_custom(
          "Stamp tidak dapat diberikan karena di luar jam operasional 17:00 - 22:00"
        ),
      status: "danger",
    });
    if (Router.query?.status == "auth") {
      setToast({
        isToast: true,
        message: () => msg_custom("Kamu telah login di device yang berbeda"),
        status: "danger",
      });
    } else if (Router.query?.message) {
      setToast({
        isToast: true,
        message: () => msg_custom(Router.query?.message),
        status: "danger",
      });
    }
  }, []);

  return (
    <Layout noBurgerMenu={true}>
      {/* FORGOT MODAL */}
      <ForgotPasswordModal
        id={modalForgotId}
        isShowing={state.forgotModalShow}
        hide={() => handleChangeState({ forgotModalShow: null })}
      />
      {/* FORGOT MODAL */}

      <section className="sc-login-page">
        <div className="login-formbox py-3">
          <div className="container mt-4">
            <h1 className="title text-center">Login</h1>
            <div className="form-box py-3">
              <HookForm
                onSubmit={handleSubmit}
                name="form-eatlahstaff"
                defaultValues={{}}
              >
                {(props) => (
                  <div className="row">
                    <div className="col-12 form-group">
                      <HookInput
                        {...props}
                        variant="underline"
                        id="staff-email"
                        label="Username"
                        name={"username"}
                        type="text"
                        labelClassName="text-gray letter-normal label-input"
                        inputClassName="input-staff"
                        placeholder="Masukkan username anda"
                        required
                      />
                    </div>
                    <div className="col-12 form-group">
                      <HookInput
                        {...props}
                        variant="underline"
                        id="staff-password"
                        label="Password"
                        name={"password"}
                        type={state.showPassword ? "text" : "password"}
                        placeholder="Masukan password anda"
                        labelClassName="text-gray letter-normal label-input"
                        required
                        inputClassName="input-staff"
                        suffix={() => (
                          <i
                            className={`air ai-eye${
                              state.showPassword ? "-slash" : ""
                            }`}
                            onClick={() =>
                              handleChangeState({
                                showPassword: !state.showPassword,
                              })
                            }
                          ></i>
                        )}
                      />
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                      <p
                        className="forgot-password-text"
                        onClick={() =>
                          handleChangeState({ forgotModalShow: modalForgotId })
                        }
                      >
                        lupa password
                      </p>
                    </div>
                    <div className="col-12 pt-3">
                      <Button
                        loader={state.isLoading}
                        variant="primary"
                        className="w-100"
                      >
                        LOGIN
                      </Button>
                    </div>
                  </div>
                )}
              </HookForm>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const getServerSideProps = (ctx) => {
  let { user_status } = TokenValidation(ctx);
  if (!user_status) return { props: {} };
  else return { redirect: { destination: "/", permanent: false } };
};
