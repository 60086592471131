/**
 * This layout will wrap all page
 */
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { RotatingLines } from "react-loader-spinner";
import { useStore } from "src/lib/store";
import {
  link_cashier_reward_history,
  link_cashier_stamp_history,
  link_cashier_login,
} from "@components/page_url";

//Components
import Toast from "./shared/toast";
import { Image } from "@components/anti";
import { HamburgerButton } from "react-hamburger-button";
import Link from "./anti/Link";

const Layout = ({ children, noBurgerMenu, fixedheader, fixedHeight }) => {
  const Router = useRouter();

  const { isLoading, setLoading } = useStore((state) => state);

  const [state, setState] = useState({
    isOpenDrawer: false,
    isNavScroll: false,
  });

  const handleChangeState = (values) => {
    setState((prev) => ({ ...prev, ...values }));
  };

  const handleNavigate = (url) => {
    handleChangeState({ isOpenDrawer: false });
    setTimeout(() => {
      Router.push(url);
    }, 500);
  };

  const handleLogout = () => {
    Cookies.remove(process.env.NEXT_PUBLIC_ACCESS_TOKEN);
    handleChangeState({ isOpenDrawer: false });
    setTimeout(() => {
      Router.push(link_cashier_login);
      window.location.reload();
    }, 500);
  };

  return (
    <div className="bg-layoutmobileframe">
      <Toast />
      <div
        id="main-children"
        className={`main-screen fadeInUp
        ${state.isOpenDrawer ? "open-drawer" : ""}`}
      >
        {/* For Cashiers ********** */}
        <div id="camera-portal" />
        {/* For Cashiers ********** */}
        <div className={`nav-drawer ${state.isOpenDrawer ? "open" : ""}`}>
          <DrawerContent
            _onNavigate={handleNavigate}
            _onLogout={handleLogout}
          />
        </div>
        <div className="screen">
          {isLoading ? (
            <div className="full-loading-overlay">
              <RotatingLines
                strokeColor="#bb283a"
                strokeWidth="5"
                animationDuration="0.75"
                width="40"
                visible={true}
              />
            </div>
          ) : null}
          <header
            className={`logo-header ${
              fixedheader ? "fixed-header" : ""
            } fadeInUp`}
          >
            {!noBurgerMenu ? (
              <div className="burger-menu">
                <div className="menu-icon">
                  <HamburgerButton
                    open={state.isOpenDrawer}
                    onClick={() =>
                      handleChangeState({ isOpenDrawer: !state.isOpenDrawer })
                    }
                    width={18}
                    height={12}
                    strokeWidth={2}
                    color="#5b171c"
                    animationDuration={0.5}
                  />
                </div>
              </div>
            ) : null}
            <Link href="/">
              <Image
                src={"/img/logo/img_header-eatlah-staff.png"}
                width={175}
                height={60}
                alt="image-logo-staff"
              />
            </Link>
          </header>
          <main className={`page-section ${fixedHeight ? "fixed-height" : ""}`}>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;

//!DRAWER COMPONENTS
const DrawerContent = ({ _onNavigate, _onLogout }) => {
  const { user_data } = useStore((state) => state);

  useEffect(() => {
    console.log(user_data);
  }, []);

  const navList = [
    {
      title: "beranda",
      url: "/",
    },
    {
      title: "stamp history",
      url: link_cashier_stamp_history,
    },
    {
      title: "reward history",
      url: link_cashier_reward_history,
    },
    {
      title: "log out",
      url: null,
    },
  ];

  return (
    <div className="drawer-content py-1">
      <div className="container mt-6">
        <p className="title-username text-center">{user_data?.name}</p>
        <p className="title-branch">{user_data?.outlet?.name}</p>
        <div className="navigation-list">
          <ul className="nav-list">
            {navList.map((item) => (
              <li
                onClick={() => {
                  if (item.url) return _onNavigate(item.url);
                  else return _onLogout();
                }}
                className="nav-list-item"
                key={item.title}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
